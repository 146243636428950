<template>
  <div class="box">
    <div class="box-toolbar">
      <div class="box-item">
        <el-input
          placeholder="请输入名称"
          v-model="search_name"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      height="67vh"
      :stripe="true"
      :data="lists"
    >
      <el-table-column
        width="80"
        label="序号"
        prop="id"
      >
      </el-table-column>

      <el-table-column
        label="名称"
        width="200"
        prop="name"
      >
      </el-table-column>

      <el-table-column
        label="反馈内容"
        prop="body"
        width="500"
      >
      </el-table-column>

      <el-table-column
        label="反馈用户"
        prop="user_id"
        width="120"
      >
      <template slot-scope="scope">
        {{scope.row.user.username}}
      </template>
      </el-table-column>

      <el-table-column
        label="用户手机"
        prop="user_id"
        width="120"
      >
      <template slot-scope="scope">
        {{scope.row.user.phone}}
      </template>
      </el-table-column>

      <el-table-column
        label="反馈时间"
        prop="created_at"
      >
      </el-table-column>

      <el-table-column
        fixed="right"
        width="190"
        label="操作"
      >
        <template
        slot-scope="scope"
         fixed="right">
          <el-dropdown
            trigger="click"
          >
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">

              <el-dropdown-item
                v-if="$can('admin/feedback/delete')"
                @click.native="handleModelDelete(scope.row)"
              >
                删除
              </el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <div class="shop-i-bottom">
      <page
        class="shop-page"
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
    </div>
  </div>
</template>

<script>

import NumberService from '@admin/services/NumberService'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  name: '',
  props: [''],
  data () {
    return {
      search_name: '',
      function: function (param) {
        return NumberService.feedback(param)
      },
      delete: function (param) {
        return NumberService.feedDel(param)
      }
    }
  },
  components: {
    Page
  },
  mixins: [pagination],

  methods: {
    // 搜索吧~
    async searchGo () {
      this.page = 1
      let param = {
        type: this.type,
        search_name: this.search_name
      }
      this.where = param
      await this.getList(param)
    }
  }
}

</script>
<style lang='' scoped>

</style>
